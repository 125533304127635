@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

body {
	font-family: 'Roboto';
	color: #303030;
	background: rgb(243, 251, 253);
}
